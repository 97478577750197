import React from 'react'

import { ButtonExtLink } from 'components/Shared/_Links'
import { ButtonLink } from 'components/Shared/_Links'

import { recentAlbum } from 'constant/member-gallery.constant'

import announcement from 'assets/files/202309各班名單公告.pdf'

//#region STYLES
import styled from 'styled-components'

const Wrapper = styled.section`
  --text-align: left;
  width: 100%;
  > div {
    max-width: 1080px;
    margin: 0 auto;
  }

  margin-bottom: var(--margin-default);
`
const RecentAlbum = styled.div`
  ${p => p.theme.mq.md`
    --flex-basis: 46%;
  `}

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .album {
    flex-basis: var(--flex-basis, 100%);
    gap: 40px;
    padding: var(--margin-default) 1rem;
    border: 0.05rem solid var(--color-gray-medium);
    font-size: calc(var(--font-size-defalut) * 0.8);
    background-color: var(--color-beige);
    p {
      --margin-bottom: 0;
    }
    p,
    h4 {
      --text-align: center;
    }
    .link {
      --border-radius: 1rem;
      width: 50%;
      margin: 2rem auto 0;
      height: 4rem;
      opacity: 0.9;
    }
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: gray;
  opacity: 0.8;
  margin-top: 10px;
  margin-bottom: 40px;
`

//#endregion

const Recent = () => {
  return (
    <Wrapper>
      <div>
        <h3 style={{marginBottom: '30px'}}>最新訊息</h3>

        <ButtonExtLink
          href={announcement}
          target='_blank'
          rel='noreferrer'
          style={{
            marginBottom: '4rem',
          }}
        >
          2023-2024學年編班名單
        </ButtonExtLink>


        <Divider />

        {/* <RecentAlbum>
          {recentAlbum.map((album, i) => {
            return (
              <div className='album' key={`album-${i}`}>
                <p>{album.time} </p>
                <h4>{album.title}</h4>
                <ButtonLink to='/member/gallery' className='link'>
                  相簿
                </ButtonLink>
              </div>
            )
          })}
        </RecentAlbum> */}

        <RecentAlbum>
          {/* {recentAlbum.map((album, i) => { */}
            {/* return ( */}
              <div className='album' key={`album-0`}>
                <p>{recentAlbum[0].time} </p>
                <h4>{recentAlbum[0].title}</h4>
                <ButtonLink to='/member/gallery' className='link'>
                  相簿
                </ButtonLink>
              </div>
            {/* ) */}
          {/* })} */}
        </RecentAlbum>
      </div>
    </Wrapper>
  )
}

export default Recent
