import React from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'

import LatestNews from 'components/member/LatestNews'
// import LostNFound from 'components/member/LostNFound'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.div`
  min-height: 100%;
  width: 100%;
`
//#endregion


const MemberHome = ({data}) => {
  const { seo } = data
  const { title, description } = seo.frontmatter.home

  return (
    <PageContent>
      <Seo title={title} description={description} />

      <Wrapper>
        <Title>
          晶晶合唱團<span>成員專區</span>
        </Title>

        <LatestNews />
      </Wrapper>
      
    </PageContent>
  )
}

export default MemberHome

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo_member" } }) {
      frontmatter {
        home {
          title
          description
        }
      }
    }
  }
`